import { useEffect, useState, useRef } from "react";
import { useSpring } from "@react-spring/web";
import hljs from "highlight.js/lib/core";

import javascript from "highlight.js/lib/languages/javascript";

hljs.registerLanguage("javascript", javascript);

export const useInterval = (ms = 1000) => {
  const [tick, setTick] = useState(0);
  useEffect(() => {
    let interval = setInterval(() => {
      setTick(cur => cur + 1);
    }, ms);
    return () => clearInterval(interval);
  }, [ms]);
  return tick;
};

const getWin = useWindow => {
  let winWidth = 1280;
  let winHeight = 800;

  if (useWindow) {
    winWidth = window.innerWidth;
    winHeight = window.innerHeight;
  }

  return [winWidth, winHeight];
};

// A hook that returns the window size, throttled by requestAnimationFrame
export const useWindowSize = () => {
  const [win, setWin] = useState(getWin);
  const handleResize = () => {
    window.requestAnimationFrame(() => {
      setWin(getWin(true));
    });
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);
  return win;
};

// A hook that returns the scrollTop position, throttled by requestAnimationFrame
export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(null);

  const handleScroll = () => {
    window.requestAnimationFrame(() => {
      setScrollTop(window.pageYOffset);
    });
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, false);
    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  return scrollTop;
};

/**
  Performs syntax highlighting on all <pre><code> in the body
  We added a 500ms delay in order for the first render to have been performed.
**/
export const useHighlight = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.querySelectorAll("pre code").forEach(el => {
        hljs.highlightElement(el);
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, []);
};

/**
  Tracks the scroll position and returns a rotation value to use in rotating elements.
**/
export const useScrollProgress = () => {
  const winHeight = useWindowSize()[1];
  const [{ progress }, api] = useSpring(
    () => ({
      progress: -45,
      immediate: true
    }),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      api.set({ progress: ((window.scrollY / 4) % 360) - 45 });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [winHeight, api]);
  return progress;
};

/**
  Tracks the scroll position and returns an offset value for the homepage menu
**/

export const useMenuOffset = ({ height = 70 }) => {
  const winHeight = useWindowSize()[1];
  const hero = useRef();
  const [{ offset }, api] = useSpring(
    () => ({
      offset: 0,
      immediate: true
    }),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      if (hero.current) {
        const scroll = window.scrollY;
        const heroRect = hero.current.getBoundingClientRect();
        const _offset = Math.min(
          height,
          Math.max(0, scroll - (heroRect.height - height))
        );
        api.set({ offset: _offset });
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [height, winHeight, api]);

  return [offset, hero];
};
