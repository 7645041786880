import React, { useState, useEffect } from "react";
import classnames from "classnames";

import Button from "./Button";

import * as css from "./Subscribe.module.css";

export const Subscribe = ({ subscribed, className, variant }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  // load campaign monitor script
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://js.createsend1.com/javascript/copypastesubscribeformlogic.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onChange = e => {
    const { value } = e.target;
    setEmail(value);
    setError(false);
  };

  const validate = () => {
    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/.test(email)) {
      return true;
    } else {
      setError("Please enter a valid email");
      return false;
    }
  };

  const onSubmit = async e => {
    if (!validate()) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      window.fathom?.trackGoal("SFWHE5AI", 0);
    }
  };

  return (
    <div
      className={classnames(css.root, className, {
        [css[variant]]: css[variant]
      })}>
      <p>
        If you would like to stay up to date on the development of this tool and
        get beta access, please subscribe below:
      </p>
      {subscribed ? (
        <div className={css.thanks}>
          <p>Thanks for subscribing! We'll be in touch very soon.</p>
        </div>
      ) : (
        <form
          onSubmit={onSubmit}
          className={classnames("js-cm-form", css.form)}
          id="subForm"
          action="https://www.createsend.com/t/subscribeerror?description="
          method="post"
          data-id="2BE4EF332AA2E32596E38B640E905619039A286580D54797EFFF92911D91E95B226A1681B6F373D185E282F6953C3494DB06D00DFCD3D1754D2F5F137E26AE87">
          <div className={css.inputWrapper}>
            <input
              autoComplete="Email"
              aria-label="Email"
              placeholder="Sign up for our newsletter"
              className={classnames(
                css.input,
                "js-cm-email-input qa-input-email"
              )}
              id="fieldEmail"
              maxLength="200"
              value={email}
              onChange={onChange}
              name="cm-yhjkjyy-yhjkjyy"
            />
            <p className={css.error}>{error}</p>
          </div>
          <div>
            <Button className={css.button} type="submit" variant={variant}>
              Notify me
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Subscribe;
