// extracted by mini-css-extract-plugin
export var blue = "Subscribe-module--blue--bw0BA";
export var button = "Subscribe-module--button--RvhB6";
export var error = "Subscribe-module--error--HnBZK";
export var form = "Subscribe-module--form--xTdWu";
export var input = "Subscribe-module--input--pQl1r";
export var inputWrapper = "Subscribe-module--inputWrapper--HB4Yf";
export var red = "Subscribe-module--red--hD6YX";
export var root = "Subscribe-module--root--V3Bwu";
export var thanks = "Subscribe-module--thanks--y9USH";
export var withError = "Subscribe-module--withError--xgQT2";