import * as React from "react";
import { Link } from "gatsby";

import Logo from "../common/Logo";

import * as css from "./Footer.module.css";

import GitHubLogo from "../../images/github.svg";
import TwitterLogo from "../../images/twitter.svg";
import InstagramLogo from "../../images/instagram.svg";

const Footer = ({ variant }) => {
  return (
    <div className={css.root}>
      <div className={css.left}>
        <Link to={"/"}>
          <Logo width={"100%"} className={css.logo} />
        </Link>
      </div>
      <div className={css.right}>
        <p>
          Mechanic is a new design framework being developed by Design Systems
          International. You can learn more about our work by visiting our{" "}
          <a href="https://designsystems.international/">website</a> or social
          media profiles:
        </p>
        <a
          href="https://github.com/designsystemsinternational/mechanic"
          target="_blank"
          rel="noreferrer">
          <GitHubLogo className={css.socialMedia} width={40} height={40} />
        </a>
        <a
          href="https://twitter.com/designsystemsin"
          target="_blank"
          rel="noreferrer">
          <TwitterLogo className={css.socialMedia} width={40} height={40} />
        </a>
        <a
          href="https://instagram.com/designsystemsinternational"
          target="_blank"
          rel="noreferrer">
          <InstagramLogo className={css.socialMedia} width={40} height={40} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
