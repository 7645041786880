import React from "react";
import { animated } from "@react-spring/web";

export const RotatingLine = ({ progress, size = 30, color }) => {
  return (
    <svg width={size} height={size}>
      <animated.line
        stroke={`var(--${color})`}
        x1="0"
        y1={size / 2}
        x2={size}
        y2={size / 2}
        transform={progress.to(p => `rotate(${p} ${size / 2} ${size / 2})`)}
      />
    </svg>
  );
};
export default RotatingLine;
