import React, { useState } from "react";
import classnames from "classnames";
import { animated } from "@react-spring/web";

import versions from "../../../versions.json";

import RotatingLine from "./RotatingLine";

import GitHubLogo from "../../images/github.svg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import * as css from "./MainMenu.module.css";

export const MainMenu = ({ title, color, className, progress }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={classnames(css.root, className, { [css[color]]: css[color] })}>
      <a className={css.logo} href="/#">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
          <circle className={css.markRed} cx="20" cy="20" r="20" />

          <animated.path
            className={css.markBlue}
            d="M0,20 a20,20 0 0,1 40,0"
            transform={progress.to(p => `rotate(${p} 20 20)`)}
          />
        </svg>
      </a>
      <button className={css.menuToggle} onClick={() => setExpanded(!expanded)}>
        {expanded ? (
          <AiOutlineClose
            size={30}
            fill={`${
              color === "blue"
                ? "#0f21c3"
                : color === "red"
                ? "#e94225"
                : "#fff"
            }`}
          />
        ) : (
          <AiOutlineMenu
            size={30}
            fill={`${
              color === "blue"
                ? "#0f21c3"
                : color === "red"
                ? "#e94225"
                : "#fff"
            }`}
          />
        )}
      </button>
      <ul
        className={classnames(css.menu, {
          [css.expanded]: expanded
        })}>
        <li>
          <a href="/#getstarted">Get Started</a>
          <RotatingLine progress={progress} color={color} />
        </li>
        <li
          className={classnames({
            [css.active]: title === "Documentation"
          })}>
          <a href={`/docs/${versions[0]}`}>Documentation</a>
          <RotatingLine progress={progress} color={color} />
        </li>
        <li className={classnames({ [css.active]: title === "About" })}>
          <a href="/about">About</a>
          <RotatingLine progress={progress} color={color} />
        </li>
        <li>
          <a
            href="https://github.com/designsystemsinternational/mechanic"
            target="_blank"
            rel="noreferrer">
            <GitHubLogo width={30} height={30} className={css.githubLogo} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MainMenu;
