// extracted by mini-css-extract-plugin
export var active = "MainMenu-module--active--Eg7aU";
export var blue = "MainMenu-module--blue--sxTGe";
export var blueBg = "MainMenu-module--blueBg--Jk0Yc";
export var blueLine = "MainMenu-module--blueLine--njnwa";
export var expanded = "MainMenu-module--expanded--Ht5fJ";
export var githubLogo = "MainMenu-module--githubLogo--q5ntE";
export var logo = "MainMenu-module--logo--2osZn";
export var markBlue = "MainMenu-module--markBlue--FvV+F";
export var markRed = "MainMenu-module--markRed--LK461";
export var menu = "MainMenu-module--menu--+EZKW";
export var menuToggle = "MainMenu-module--menuToggle--CmlQl";
export var menuWrapper = "MainMenu-module--menuWrapper--1GX-q";
export var red = "MainMenu-module--red--Fdx3W";
export var redBg = "MainMenu-module--redBg--VWE0n";
export var redLine = "MainMenu-module--redLine--vjvnA";
export var root = "MainMenu-module--root--SiOaP";
export var transparent = "MainMenu-module--transparent--ClM6F";
export var wrapper = "MainMenu-module--wrapper--oW-Ir";